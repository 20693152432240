// Datei: Head.js
import './Contentbox.css';
import backgroundImage from '../images/backgrounds/fourthComponent.png';
import React, { useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import comic1 from '../images/comic/Panel-1_R0.jpg'
import comic2 from '../images/comic/Panel-2_R0.jpg'
import comic3 from '../images/comic/Panel-3_R0.jpg'
import comic4 from '../images/comic/Panel-4_R0.jpg'
import comic5 from '../images/comic/Panel-5_R0.jpg'
import comic6 from '../images/comic/Panel-6_R0.jpg'
import comic7 from '../images/comic/Panel-7_R0.jpg'
import comic8 from '../images/comic/Panel-8_R0.jpg'
import comic9 from '../images/comic/Panel-9_R0.jpg'
import comic10 from '../images/comic/Panel-10_R0.jpg'
import comic11 from '../images/comic/Panel-11_R0.jpg'
import comic12 from '../images/comic/Panel-12_R0.jpg'
import comic13 from '../images/comic/Panel-13_R0.jpg'
import comic14 from '../images/comic/Panel-14_R0.jpg'
import comic15 from '../images/comic/Panel-15_R0.jpg'

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <div>
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      <h1></h1>
      <div className="page-image">{props.image}</div>
      <p>{props.children}</p>
      <p>{props.number}</p>
    </div>
  );
});

function Contentbox(props) {

  const [inputText, setInputElement] = useState("");
  const [text, setText] = useState("");
  const printText = () => {
    setText(inputText);
    setInputElement("");
  };

  return (
    <div className="contentbox-container">
      <div className="contentbox-content">
        <HTMLFlipBook
          width={500}
          height={500}
          minWidth={315}
          minHeight={420}
          showCover={true}
          flippingTime={1000}
          style={{ margin: "0 auto" }}
          maxShadowOpacity={0.5}
          className="album-web"
        >
          <PageCover><img src={comic1} height="500px"/></PageCover>
          
          <Page>
          <img src={comic2} height="500px"/>
            
          </Page>
          <Page>
            <img src={comic3} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic4} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic5} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic6} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic7} height="500px"/>
            
          </Page>
          <Page>
          <img src={comic8} height="500px"/>
            
          </Page>
          <Page>
          <img src={comic9} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic10} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic11} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic12} height="500px"/>
            
          </Page>
          <Page>
          <img src={comic13} height="500px"/>
           
          </Page>
          <Page>
          <img src={comic14} height="500px"/>
         
          </Page>
          <Page>
          <img src={comic15} height="500px"/>
         
          </Page>

          
        </HTMLFlipBook>
      </div>
    </div>
   
  );
}

export default Contentbox;
