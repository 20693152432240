import React, { useState } from "react";
import "./Roadmap.css";

function Roadmap() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(""); // Nachricht des Popups
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  // Punkte und Nachrichten definieren
  const points = [
    { x: 325, y: 496, message: "Tier One" },
    { x: 500, y: 300, message: "Tier Two" },
    { x: 700, y: 600, message: "Tier Three" },
    { x: 1200, y: 450, message: "Tier Four" },
  ];

  const handleMouseEnter = (e, message) => {
    const rect = e.target.getBoundingClientRect();
    setPopupPosition({
      x: rect.left + rect.width / 2,
      y: rect.top - 10, // 10px oberhalb des Punktes
    });
    setPopupContent(message); // Nachricht des aktuellen Punktes setzen
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  return (
    <div className="roadmap-container">
      <h1 className="roadmap-heading">Roadmap</h1> {/* Überschrift */}
      {points.map((point, index) => (
        <div
          key={index}
          className="roadmap-point"
          onMouseEnter={(e) => handleMouseEnter(e, point.message)}
          onMouseLeave={handleMouseLeave}
          style={{
            left: `${(point.x / 2000) * 100}%`, // X-Koordinate relativ zur Bildbreite
            top: `${(point.y / 981) * 100}%`, // Y-Koordinate relativ zur Bildhöhe
          }}
        ></div>
      ))}
      {showPopup && (
        <div
          className="roadmap-popup"
          style={{
            left: `${popupPosition.x}px`,
            top: `${popupPosition.y}px`,
          }}
        >
          {popupContent}
        </div>
      )}
    </div>
  );
}

export default Roadmap;
