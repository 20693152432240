import React, { useState } from "react";
import "./Head.css"; // Deine CSS-Datei
import tonyLogo from "../images/icons/Fav_R3.png";

function Head() {
  const [showButton, setShowButton] = useState(true);
  const [startAnimation, setStartAnimation] = useState(false);

  const handleStart = () => {
    setStartAnimation(true); // Animation starten
    setShowButton(false); // Button ausblenden
  };

  const handleAnimationEnd = () => {
    setStartAnimation(false); // Animation stoppen
    setShowButton(true); // Button erneut anzeigen
  };

  return (
    <div className="head-container">
      <div className="head-content">
        <div className="hgrid-container">
          <div className="ha"></div>
          <div className="hb">
            {/* Button anzeigen, wenn der Button sichtbar ist */}
            {showButton && (
              <button className="start-button" onClick={handleStart}>
                Are You Ready?
              </button>
            )}

            {/* Star Wars Intro */}
            {startAnimation && (
              <div
                className="star-wars-intro"
                // Überwache das Ende der Animation
              >
                <p className="intro-text">Tony’s back...</p>
                <h2 className="main-logo">
                  <img src={tonyLogo} alt="Star Wars Logo" />
                </h2>
                <div className="main-content">
                  <div
                    className="title-content"
                    onAnimationEnd={handleAnimationEnd}
                  >
                    <p className="content-header">
                      TONY & CLONES
                      <br />
                      SEASON II
                    </p>
                    <br />
                    <p className="content-body">
                      Meet Tony. Bi-polar memelord and NFT critic. What you
                      don’t know is that Tony’s great, great, great,
                      grand-father is Anthonis Mor van Dashorst, the man who
                      made the portrait mainstream 500 years ago. As more
                      “portraits” appear in the Metaverse as NFTs, Tony feels
                      his ancestor’s legacy is being shat on. Tony thinks,
                      “Enough is enough. Vengeance is the only answer! It’s time
                      to rid the Metaverse of garbage NFTs.” Follow Tony’s story
                      as Chapter One is being released along with the first NFT
                      Collection ‘Tony & Clones’. Chapter Two will be released
                      with the second NFT collection ‘Nemesis'. A feature Movie
                      will unveil Chapter Three. Challenge other NFT holders for
                      rewards and glory. A Play-to-Earn Game re-lives the Clones
                      most epic battles in player versus player contest. Check
                      out our Roadmap for further details. Do your part. Own
                      your Clone. Clean the Metaverse!
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Head;
