// Datei: App.js
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Head from "./components/Head";
import Roadmap from "./components/Roadmap";
import Contentbox from "./components/Contentbox";
import Navbar from "./components/Navbar";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div
              className="app-container"
              style={{
                height: "100vh",
                overflow: "auto",
                backgroundColor: "black",
              }}
            >
              <Navbar />
              <Home />
              <Head />
              <Roadmap />
              <Contentbox />
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
